import { FOV } from './webgl/gum/viewer'

const MIN_FOV = 30
const STEP = 10
const STEP_ZOOM = 5
let currentFov = FOV

export const resetFov = () => {
  currentFov = FOV
}

export const getFov = (currentFov) => Math.tan(((Math.PI / 180) * currentFov / 2))

export const initControlsButton = (g) => {
  const Buttons = {
    left: document.querySelector('.control__btn--left'),
    right: document.querySelector('.control__btn--right'),
    up: document.querySelector('.control__btn--up'),
    down: document.querySelector('.control__btn--down'),
    zoomin: document.querySelector('.control__btn--in'),
    zoomout: document.querySelector('.control__btn--out')
  }
  Buttons.left.addEventListener('click', () => {
    console.log('click button left')
    g.v.controls.rotateHoriz(-STEP * Math.PI / 180)
  })

  Buttons.right.addEventListener('click', () => {
    console.log('click button right')
    g.v.controls.rotateHoriz(STEP * Math.PI / 180)
  })

  Buttons.up.addEventListener('click', () => {
    console.log('click button up')
    g.v.controls.rotateVertic(-STEP * Math.PI / 180)
  })

  Buttons.down.addEventListener('click', () => {
    console.log('click button down')
    g.v.controls.rotateVertic(STEP * Math.PI / 180)
  })

  Buttons.zoomin.addEventListener('click', () => {
    console.log('click button zoomin')

    currentFov = (currentFov - STEP_ZOOM) <= MIN_FOV ? MIN_FOV : currentFov - STEP_ZOOM

    const fov = getFov(currentFov)
    g.v.camera.fov = (360 / Math.PI) * Math.atan(fov * (g.v.height / 1100))
    g.v.camera.updateProjectionMatrix()
  })

  Buttons.zoomout.addEventListener('click', () => {
    console.log('click button zoomout')

    currentFov = (currentFov + STEP_ZOOM) >= FOV ? FOV : currentFov + STEP_ZOOM

    const fov = getFov(currentFov)
    g.v.camera.fov = (360 / Math.PI) * Math.atan(fov * (g.v.height / 1100))
    g.v.camera.updateProjectionMatrix()
  })
}

// add to Orbit control npm
// this.rotateHoriz = function(degrees) {
//   rotateLeft(degrees);
//   this.update();
// }
//
// this.rotateVertic = function(degrees) {
//   rotateUp(degrees);
//   this.update();
// }
//
// this.zoomIn = function () {
//   dollyOut(getZoomScale());
//   this.update();
//
// };
//
// this.zoomOut = function () {
//   dollyIn(getZoomScale());
//   this.update();
// };
