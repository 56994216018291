import { panoramsData } from '../../panorams-data'

const SRCS = {
  preloader: [...panoramsData],
  main: [
    {
      name: '1',
      path: '../assets/img/360/1.jpg',
      type: 'texture'
    },
    {
      name: '2',
      path: '../assets/img/360/2.jpg',
      type: 'texture'
    },
    {
      name: '3',
      path: '../assets/img/360/3.jpg',
      type: 'texture'
    },
    {
      name: '4',
      path: '../assets/img/360/4.jpg',
      type: 'texture'
    },
    {
      name: '5',
      path: '../assets/img/360/5.jpg',
      type: 'texture'
    },
    {
      name: '6',
      path: '../assets/img/360/6.jpg',
      type: 'texture'
    },
    {
      name: '7',
      path: '../assets/img/360/7.jpg',
      type: 'texture'
    },
    {
      name: '8',
      path: '../assets/img/360/8.jpg',
      type: 'texture'
    },
    {
      name: '9',
      path: '../assets/img/360/9.jpg',
      type: 'texture'
    },
    {
      name: '10',
      path: '../assets/img/360/10.jpg',
      type: 'texture'
    },
    {
      name: '11',
      path: '../assets/img/360/11.jpg',
      type: 'texture'
    },
    {
      name: '12',
      path: '../assets/img/360/12.jpg',
      type: 'texture'
    },
    {
      name: '13',
      path: '../assets/img/360/13.jpg',
      type: 'texture'
    },
    {
      name: '14',
      path: '../assets/img/360/14.jpg',
      type: 'texture'
    },
    {
      name: '15',
      path: '../assets/img/360/15.jpg',
      type: 'texture'
    },
    {
      name: '16',
      path: '../assets/img/360/16.jpg',
      type: 'texture'
    },
    {
      name: '17',
      path: '../assets/img/360/17.jpg',
      type: 'texture'
    },
    {
      name: '18',
      path: '../assets/img/360/18.jpg',
      type: 'texture'
    },
    {
      name: '19',
      path: '../assets/img/360/19.jpg',
      type: 'texture'
    },
    {
      name: '20',
      path: '../assets/img/360/20.jpg',
      type: 'texture'
    },
    {
      name: '21',
      path: '../assets/img/360/21.jpg',
      type: 'texture'
    }
  ]

}


export default SRCS
