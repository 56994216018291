import * as THREE from 'three'
import Stats from 'three/examples/jsm/libs/stats.module'
import { OrbitControls } from '../../../orbit-controls-custom'

export const FOV = window.innerWidth <= 768 ? 90 : 80
// const FOV = 70
const START_HEIGHT = 1100
const START_TAN_FOV = Math.tan(((Math.PI / 180) * FOV / 2))

const Viewer = class {
  constructor (data) {
    var that = this
    this.container = data.container

    this.scene = new THREE.Scene()
    this.renderData = data.renderData ? data.renderData : {}
    this.renderer = new THREE.WebGLRenderer(this.renderData)
    // this.renderer.outputEncoding = THREE.sRGBEncoding
    this.renderer.setPixelRatio(1.5)
    this.container.appendChild(this.renderer.domElement)

    this.setSize()
    this.camera = new THREE.PerspectiveCamera(FOV, this.width / this.height, 0.1, 1000)

    this.camera.position.set(0, 0, 0.0001)
    this.camera.fov = (360 / Math.PI) * Math.atan(START_TAN_FOV * (this.height / START_HEIGHT))
    this.camera.updateProjectionMatrix()
    this.scene.add(this.camera)
    this.stats = new Stats()
    // this.container.appendChild(this.stats.dom)
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.enableZoom = false
    this.controls.enablePan = true
    this.controls.rotateSpeed = -0.3

    window.addEventListener('resize', function () {
      that.onResize()
    })

    window.addEventListener('orientationchange', function () {
      that.onResize()
    })
  }

  setSize () {
    this.width = this.container.offsetWidth || window.innerWidth
    this.height = this.container.offsetHeight || window.innerHeight
    // this.width = window.innerWidth
    // this.height = window.innerHeight
    this.renderer.setSize(this.width, this.height)
  }

  onResize () {
    this.setSize()
    if (this.camera.type === 'PerspectiveCamera') {
      this.camera.fov = (360 / Math.PI) * Math.atan(START_TAN_FOV * (this.height / START_HEIGHT))

      this.camera.aspect = this.width / this.height
    } else if (this.camera.type === 'OrthographicCamera') {
      this.camera.left = this.width / -2
      this.camera.right = this.width / 2
      this.camera.top = this.height / 2
      this.camera.bottom = this.height / -2
    }
    this.camera.updateProjectionMatrix()
  }

  update () {
    this.controls.update()
    this.renderer.render(this.scene, this.camera)
    // this.stats.update()
  }
}

export default Viewer
