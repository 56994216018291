export default class StateMachine {
  constructor (current) {
    this.current = null
  }

  setState (target) {
    const current = this.current

    if (current && current.name === target.name) {
      // console.log('Состояние уже принято')
      return
    }

    if (!target.condition()) {
      console.log('Переход к ' + target.name + ' отменен')
      return
    }

    current && current.onEnd()
    target.onStart()

    console.log('__SM__ ', current && current.name, ' ---> ', target.name)
    this.current = target
  }
}
