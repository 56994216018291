export const Buttons = {
  START: document.querySelector('.start-screen__button'),
  SELECT_CLOSE: document.querySelector('.select__close'),
  SELECT_EXCURSION: document.querySelector('.select__content-button--excursion'),
  SELECT_QUIZ: document.querySelector('.select__content-button--quiz'),
  AUTH_CLOSE: document.querySelector('.authorization__close'),
  NEXT_LOCATION: document.querySelector('.navigation__button'),
  AUTH_VK: document.querySelector('.authorization__content-button--vk'),
  AUTH_OK: document.querySelector('.authorization__content-button--ok'),
  AUTH_FB: document.querySelector('.authorization__content-button--fb'),
  RATING: document.querySelector('.header__rating'),
  RATING_CLOSE: document.querySelector('.rating__close'),

  EXCURSION_POPUP_START: document.querySelector('.excursion-begin__start'),
  QUIZ_POPUP_START: document.querySelector('.quiz-begin__start'),
  QUIZ_POPUP_CLOSE: document.querySelector('.quiz-begin__popup-close'),
  EXCURSION_POPUP_CLOSE: document.querySelector('.excursion-begin__popup-close')
}

export const Elements = {
  START: document.querySelector('.start-screen'),
  START_CONTENT: document.querySelector('.start-screen__content'),
  SELECT: document.querySelector('.select'),
  AUTH: document.querySelector('.authorization'),
  EXCURSION_BEGIN: document.querySelector('.excursion-begin'),
  QUIZ_BEGIN: document.querySelector('.quiz-begin'),
  NAVIGATION: document.querySelector('.navigation'),
  NAVIGATION_ITEMS: document.querySelectorAll('.navigation__item'),
  TIMER: document.querySelector('.header__timer-container'),
  TIMER_ICON: document.querySelector('.rating__timer-icon'),
  RATING: document.querySelector('.rating'),
  FINAL_SCREEN: document.querySelector('.final-screen'),

  EXCURSION_POPUP: document.querySelector('.excursion-begin__popup'),
  QUIZ_POPUP: document.querySelector('.quiz-begin__popup')
}

export const Socials = {
  VK: 'vkontakte',
  FB: 'facebook',
  OK: 'odnoklassniki'
}
