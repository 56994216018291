import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import { panoramsData } from './panorams-data'
import { Buttons, Elements } from './const'
import { appStateList } from './main'

Vue.use(VueYoutube)

export const quiz = new Vue({
  el: '#quiz',

  data: {
    isPopupShow: false,
    isVideoShow: true,
    isMainQuestionShow: false,
    isAdditionalQuestionShow: false,
    currentLocation: 1,
    currentQuiz: false,
    cbNextPanorama: '',
    swiper: null,
    appStateMachine: null
  },

  mounted () {
    this.currentQuiz = panoramsData[this.currentLocation - 1]
  },

  methods: {
    selectAnswerMain (evt) {
      if (!this.currentQuiz.task.isMultiMain) {
        this.$refs.answers.forEach((answer) => {
          answer.checked = false
        })

        evt.target.checked = true
        return
      }
    },

    checkCheckbox () {
      for (let i = 0; i < this.$refs.answers.length; i++) {
        this.$refs.nextButton.disabled = true
        if (this.$refs.answers[i].checked) {
          this.$refs.nextButton.disabled = false
          return
        }
      }
    },

    selectAnswerAdditional (evt) {
      if (!this.currentQuiz.task.isMultiAdditional) {
        this.$refs.answersAdditional.forEach((answer) => {
          answer.checked = false
        })

        evt.target.checked = true
        return
      }
    },

    checkMainAnswer () {
      let isDone = true
      this.$refs.answers.forEach((answer, index) => {
        if (this.currentQuiz.task.mainAnswers[index].isTrue !== answer.checked) {
          isDone = false
        }

        if (answer.checked && (this.currentQuiz.task.mainAnswers[index].isTrue === false)) {
          answer.parentElement.classList.add('quiz__item--error')
          answer.checked = false
          answer.disabled = true
        }

        if (answer.checked && (this.currentQuiz.task.mainAnswers[index].isTrue === true)) {
          answer.parentElement.classList.add('quiz__item--done')
          answer.disabled = true
        }

      })

      if (this.currentQuiz.task.isMultiMain) {
        setTimeout(() => {
          this.$refs.answers.forEach((answer, index) => {
            answer.parentElement.classList.remove('quiz__item--error')
            answer.parentElement.classList.remove('quiz__item--done')
            answer.checked = false
            answer.disabled = false
            this.$refs.nextButton.disabled = true
          })
        }, 1000)
      }

      if (isDone) {
        console.log('правильный ответ')
        document.querySelector('.label').classList.add('label--done')
        setTimeout(() => {
          document.querySelector('.navigation-button').disabled = false
          this.closePopup()
          this.isVideoShow = true
          this.isMainQuestionShow = false
          this.$refs.nextButton.disabled = true

          if (this.currentLocation === 21) {
            console.log('завершить')
            document.querySelector('.final-screen__button--end').click()
          }
        }, 1000)
      } else {
        console.log('не правильный ответ')
      }
    },

    checkAdditionalAnswer () {
      let isDoneAdditional = true
      this.$refs.answersAdditional.forEach((answer, index) => {
        if (this.currentQuiz.task.additionalAnswers[index].isTrue !== answer.checked) {
          isDoneAdditional = false
        }

        if (answer.checked && (this.currentQuiz.task.additionalAnswers[index].isTrue === false)) {
          answer.parentElement.classList.add('quiz__item--error')
        }

        if (answer.checked && (this.currentQuiz.task.additionalAnswers[index].isTrue === true)) {
          answer.parentElement.classList.add('quiz__item--done')
        }
      })

      if (isDoneAdditional) {
        console.log('правильный ответ на доп вопрос')
        setTimeout(() => {
          this.cbNextPanorama()
          this.isPopupShow = false
          this.isAdditionalQuestionShow = false
        }, 1000)
      } else {
        console.log('не правильный ответ на доп вопрос')
      }
    },

    showAdditionalAnswer (cb) {
      if (!this.currentQuiz.task.additionalQuestion) {
        cb()
        return
      }

      this.isVideoShow = false
      this.isPopupShow = true
      this.isAdditionalQuestionShow = true
    },

    closePopup () {
      this.isPopupShow = false

      this.$refs.youtube.player.pauseVideo()
    },

    playVideo () {
      this.$refs.youtube.player.playVideo()
    },

    nextPanorama (cb, isQuiz) {
      this.cbNextPanorama = cb
      // if (isQuiz) {
      if (false) {
        this.showAdditionalAnswer(cb)
        return
      }

      cb()
    },

    endVideo () {
      console.log('Видео просмотрено')
      if (this.isQuiz) {
        this.isVideoShow = false
        this.currentQuiz.task.isVideoLook = true
        this.isMainQuestionShow = true
      } else {
        this.closePopup()
      }
    }
  },
  computed: {

  },
  watch: {
    currentLocation: function () {
      if (!Elements.NAVIGATION_ITEMS[this.currentLocation - 1]) {
        this.currentLocation = 1
      }

      this.currentQuiz = panoramsData[this.currentLocation - 1]

      if (this.currentQuiz.task.videoSrc) {
        this.isVideoShow = true
      }
    },

    isPopupShow: function () {
      if (this.isVideoShow && this.isPopupShow) {
        this.playVideo()
      }
      if (window.innerWidth <= 768) {
        const header = document.querySelector('.header')
        this.isPopupShow ? header.classList.add('hide') : header.classList.remove('hide')
      }
    }
  }
})
