import * as THREE from 'three'

const MAX_POLAR_ANGLE = 2.5
const MIN_POLAR_ANGLE = 0.95

export const panoramsData = [
  {
    name: '1',
    path: '../assets/img/360-mobile/1.jpg',
    type: 'texture',
    maxAzimuthAngle: 0.14886854767155736,
    minAzimuthAngle: -1.1008978840778918,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -1.4, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'В каком городе находится завод?',
      mainAnswers: [
        {
          text: 'Москва',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Санкт-Петербург',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Серпухов',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Лапшинск',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'upvOUC8JkQc',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(1.0, 1.44, 20),
      dotNextLocationRotate: new THREE.Vector3(0.180, 0.890, 0)
    }
  },
  {
    name: '2',
    path: '../assets/img/360-mobile/2.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 2.420, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Чего нельзя делать на заводе?',
      mainAnswers: [
        {
          text: 'Бегать (скользкие полы)',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Ходить вне отмеченных дорожек',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Засовывать руки в движущиеся механизмы',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Свистеть',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'qS9H9cHKQrQ',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, -2.410, 20),
      dotNextLocationRotate: new THREE.Vector3(-0.020, 1.240, 0)
    }
  },
  {
    name: '3',
    path: '../assets/img/360-mobile/3.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 9.270, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Что нужно надеть?',
      mainAnswers: [
        {
          text: 'Халат',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Спецобувь',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Шапочку',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Костюм мукомола',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: '9xDIh-P8mpQ',
      isVideoLook: false,
      isMultiAdditional: true,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0.170, -2.330, 20),
      dotNextLocationRotate: new THREE.Vector3(-0.040, -0.160, 0)
    }
  },
  {
    name: '4',
    path: '../assets/img/360-mobile/4.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -2.050, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Из чего сделано пюре?',
      mainAnswers: [
        {
          text: 'Хлопья из натурального картофеля',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Cоевый концентрат',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Переработанный батат',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Мука',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'WOYKc1QPTKs',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 1.762, 20),
      dotNextLocationRotate: new THREE.Vector3(0.140, -2.400, 0)
    }
  },
  {
    name: '5',
    path: '../assets/img/360-mobile/5.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 0, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Для чего нужен рентген при производстве пюре?',
      mainAnswers: [
        {
          text: 'Для контроля качества продукта',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Для красоты',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Случайно попал в кадр',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Чтобы сделать флюорографию при необходимости',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'QSwFQlcbRgg',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [

      ],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 0, 20),
      dotNextLocationRotate: new THREE.Vector3(-0.080, -0.840, 0)
    }
  },
  {
    name: '6',
    path: '../assets/img/360-mobile/6.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 2.130, 0),
    task: {
      isMultiMain: false,

      mainQuestion: 'Как очищается картофель для пюре?',
      mainAnswers: [
        {
          text: 'Вручную, теми, кто не прошел этот квест',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Трением друг о друга',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Он вообще не очищается',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Кожуру ошпаривают и удаляют щетками',
          isTrue: true,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'TdKo4VBPY5M',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0.130, -2.342, 20),
      dotNextLocationRotate: new THREE.Vector3(0, 0.730, 0)
    }
  },
  {
    name: '7',
    path: '../assets/img/360-mobile/7.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 3.080, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Мука из каких областей России используется для производства лапши быстрого приготовления?',
      mainAnswers: [
        {
          text: 'Рязанской',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Тульской',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Южной',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Тверской',
          isTrue: true,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'd4cptfpWLWM',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 3.150, 20),
      dotNextLocationRotate: new THREE.Vector3(0.060, 2.850, 0)
    }
  },
  {
    name: '8',
    path: '../assets/img/360-mobile/8.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -1.580, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'До какой толщины раскатывается тесто для лапши быстрого приготовления?',
      mainAnswers: [
        {
          text: '1 мм',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'От 2 до 5 мм',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Около 1 см',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'На глаз, чтобы тонкое было',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'AnyG9QC1Ky4',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [

      ],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 1.690, 20),
      dotNextLocationRotate: new THREE.Vector3(0, 0.970, 0)
    }
  },
  {
    name: '9',
    path: '../assets/img/360-mobile/9.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: 1.8,
    minPolarAngle: 1,
    startMeshRotation: new THREE.Vector3(0, 1.480, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Что придает лапше волнистую форму?',
      mainAnswers: [
        {
          text: 'Специальные ножи',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Разность скоростей транспортёра',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Умелый кулинар',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Формочка для теста',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'k_CBSTHRho4',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0.160, -2.333, 20),
      dotNextLocationRotate: new THREE.Vector3(0, -0.700, 0)
    }
  },
  {
    name: '10',
    path: '../assets/img/360-mobile/10.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 2.290, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Какая вода используется для замеса теста?',
      mainAnswers: [
        {
          text: 'Водопроводная, фильтрованная',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Очищенная привозная',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Минеральная газированная высшего качества',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Из собственной артезианской скважины (Тарусский шельф)',
          isTrue: true,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'B9rxdYQK0jg',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0.190, -2.560, 20),
      dotNextLocationRotate: new THREE.Vector3(-2.822, 0.058, -3.070)
    }
  },
  {
    name: '11',
    path: '../assets/img/360-mobile/11.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -1.880, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько упаковок продукции ежегодно производится на заводе?',
      mainAnswers: [
        {
          text: '1 млн',
          isTrue: false,
          isChecked: false
        },
        {
          text: '100 млн',
          isTrue: false,
          isChecked: false
        },
        {
          text: '750 млн',
          isTrue: false,
          isChecked: false
        },
        {
          text: '1.5 млрд',
          isTrue: true,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'oBYuhZU3vrU',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [

      ],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0.4600, 1.780, 20),
      dotNextLocationRotate: new THREE.Vector3(-0.090, 0.900, 0)
    }
  },
  {
    name: '12',
    path: '../assets/img/360-mobile/12.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0.060, -3.955, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Что здесь проверяют:',
      mainAnswers: [
        {
          text: 'Сырье',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'IQ',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Готовую продукцию',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Чувство юмора',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'DCTZW-OWA8s',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, -2.350, 20),
      dotNextLocationRotate: new THREE.Vector3(0, -1.800, 0)
    }
  },
  {
    name: '13',
    path: '../assets/img/360-mobile/13.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: 1.9,
    minPolarAngle: 0.9,
    startMeshRotation: new THREE.Vector3(0, -5.450, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Из чего сделан бульон?',
      mainAnswers: [
        {
          text: 'Мясо',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Натуральные сушеные овощи',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Пряности и специи',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Звездная пыль',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'cRffaZdlJ0k',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 1.120, 20),
      dotNextLocationRotate: new THREE.Vector3(0.080, -1.980, 0)
    }
  },
  {
    name: '14',
    path: '../assets/img/360-mobile/14.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 2.290, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько супа можно сварить из 90-грамовой упаковки бульона?',
      mainAnswers: [
        {
          text: '3 литра',
          isTrue: false,
          isChecked: false
        },
        {
          text: '5 литров',
          isTrue: false,
          isChecked: false
        },
        {
          text: '10 литров',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Больше 20 литров',
          isTrue: true,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'pdrrrRXANWU',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, -2.450, 20),
      dotNextLocationRotate: new THREE.Vector3(0, 3.180, 0)
    }
  },
  {
    name: '15',
    path: '../assets/img/360-mobile/15.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: 1.75,
    minPolarAngle: 1.15,
    startMeshRotation: new THREE.Vector3(0, 3.020, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько тонн муки в день расходуется на заводе:',
      mainAnswers: [
        {
          text: '100 т',
          isTrue: false,
          isChecked: false
        },
        {
          text: '15 т',
          isTrue: false,
          isChecked: false
        },
        {
          text: '250 т',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Мука не используется на заводе',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: '0A8dw77wD1Q',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 3.220, 20),
      dotNextLocationRotate: new THREE.Vector3(0, 1.680, 0)
    }
  },
  {
    name: '16',
    path: '../assets/img/360-mobile/16.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: 1.55,
    minPolarAngle: 1.1,
    startMeshRotation: new THREE.Vector3(0, 2.880, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Какие производственные линии установлены на заводе?',
      mainAnswers: [
        {
          text: 'Японские линии',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Итальянские линии',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'С миру по гайке',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Параллельные линии',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'p6GqeqljQaQ',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, -3.000, 20),
      dotNextLocationRotate: new THREE.Vector3(0.240, -2.290, 0)
    }
  },
  {
    name: '17',
    path: '../assets/img/360-mobile/17.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 0.380, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько порций лапши в одной пачке яичной лапши 400 гр?',
      mainAnswers: [
        {
          text: 'Насыпано на глазок',
          isTrue: false,
          isChecked: false
        },
        {
          text: '2 порции',
          isTrue: false,
          isChecked: false
        },
        {
          text: '6 готовых брикетов',
          isTrue: true,
          isChecked: false
        },
        {
          text: '10 пакетиков',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'y4pwIIma9qI',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, -0.540, 20),
      dotNextLocationRotate: new THREE.Vector3(-0.270, 1.330, 0)
    }
  },
  {
    name: '18',
    path: '../assets/img/360-mobile/18.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, 0, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько коробок может упаковывать робот в минуту?',
      mainAnswers: [
        {
          text: '200 коробок',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Бережно 1 коробочку',
          isTrue: false,
          isChecked: false
        },
        {
          text: '80 коробок',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Варьируется в зависимости от загруженности',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: '_mp8tIMZyOY',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 0, 20),
      dotNextLocationRotate: new THREE.Vector3(0.730, 1.770, 0)
    }
  },
  {
    name: '19',
    path: '../assets/img/360-mobile/19.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -3.250, 0),
    task: {
      isMultiMain: true,
      mainQuestion: 'Что умеет делать робот-паллетизатор (в простонародье "осьминог")?',
      mainAnswers: [
        {
          text: 'Потребляет энергию равную 25 лампочкам в час',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Снижает расход упаковочной пленки в 2 раза',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Печатает и клеит информацию на упакованный паллет',
          isTrue: true,
          isChecked: false
        },
        {
          text: 'Жужжит под Рахманинова',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: '5h4cCVLPLGU',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 3.190, 20),
      dotNextLocationRotate: new THREE.Vector3(0, -1.500, 0)
    }
  },
  {
    name: '20',
    path: '../assets/img/360-mobile/20.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -2.500, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Сколько футбольных полей занимает склад готовой продукции и сырья?',
      mainAnswers: [
        {
          text: '6',
          isTrue: true,
          isChecked: false
        },
        {
          text: '20',
          isTrue: false,
          isChecked: false
        },
        {
          text: '1',
          isTrue: false,
          isChecked: false
        },
        {
          text: '35',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'EG7QWf5REcQ',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 2.285, 20),
      dotNextLocationRotate: new THREE.Vector3(0, -0.750, 0)
    }
  },
  {
    name: '21',
    path: '../assets/img/360-mobile/21.jpg',
    type: 'texture',
    minAzimuthAngle: -Infinity,
    maxAzimuthAngle: Infinity,
    maxPolarAngle: MAX_POLAR_ANGLE,
    minPolarAngle: MIN_POLAR_ANGLE,
    startMeshRotation: new THREE.Vector3(0, -0.810, 0),
    task: {
      isMultiMain: false,
      mainQuestion: 'Во сколько стран экспортируется Роллтон?',
      mainAnswers: [
        {
          text: '10',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Более 20',
          isTrue: true,
          isChecked: false
        },
        {
          text: '5',
          isTrue: false,
          isChecked: false
        },
        {
          text: 'Во все страны мира',
          isTrue: false,
          isChecked: false
        }
      ],
      isMainDone: false,
      videoSrc: 'O3gwWro5VS4',
      isVideoLook: false,
      isMultiAdditional: false,
      additionalQuestion: '',
      additionalAnswers: [],
      isAdditionalDone: false,
      dot: new THREE.Vector3(0, 0, -20),
      dotNextLocation: new THREE.Vector3(0, 0, -20),
      dotRotate: new THREE.Vector3(0, 0.850, 20),
      dotNextLocationRotate: new THREE.Vector3(0.150, -1.850, 0)
    }
  }
]
