import GUM from './gum/gum'
import SRCS from './srcs'
import * as THREE from 'three'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'

import { panoramsData } from '../../panorams-data'
import { currentLocation } from '../../main'
import { quiz } from '../../quiz'
import Loader from './gum/loader'
import { initControlsButton } from '../controls-button'

const container = document.querySelector('#webgl_container')

export const director = {
  init: function (initApp) {
    function hideSimplePreloader () {
      document.querySelector('#simple_preloader').style.display = 'none'
    }

    function changeSimpleProgress (p) {
      document.querySelector('.spinner__percent').innerHTML = Math.round(p * 100) + '%'
    }

    this.g = new GUM({
      container: container,
      renderData: {
        antialias: true,
        alpha: true
      }
    }, {
      srcs: SRCS.preloader,
      loadCb: () => {
        hideSimplePreloader()

        for (let texturesKey in this.g.d.res.textures) {
          this.g.d.res.textures[texturesKey].minFilter = THREE.LinearFilter
        }

        initScene(this.g, initApp)
      },
      progressCb: changeSimpleProgress
    })

    return this
  }
}

const initScene = (gum, initApp) => {
  const g = gum

  g.d_hideTextures = new Loader({
    srcs: SRCS.main,
    loadCb: () => {
      console.log('Загружен текстуры в высоком разрешении')

      for (let texturesKey in g.d_hideTextures.res.textures) {
        g.d_hideTextures.res.textures[texturesKey].minFilter = THREE.LinearFilter
      }

      g.v.scene.children[1].children[0].material.dispose()
      g.v.scene.children[1].children[0].material.map = g.d_hideTextures.res.textures[currentLocation]

      for (let texturesKey in g.d.res.textures) {
        g.d.res.textures[texturesKey].dispose()
        // delete g.d.res.textures[texturesKey]
        g.v.renderer.renderLists.dispose()
      }
    }
  })
  console.log(g)
  const texture = g.d.res.textures['1']

  const sphereGeometry = new THREE.SphereGeometry(1, 50, 50)
  const sphereMaterial = new THREE.MeshBasicMaterial({
    map: texture,
    side: THREE.BackSide,
    wireframe: false
  })
  const mesh = new THREE.Mesh(sphereGeometry, sphereMaterial)
  // mesh.rotation.y += 0
  mesh.scale.set(-1, 1, 1)
  // g.v.scene.add(mesh)

  const rotationWrapper = new THREE.Object3D()

  g.v.scene.add(rotationWrapper.add(mesh))
  rotationWrapper.rotation.setFromVector3(panoramsData[0].startMeshRotation)

  const dotGeometry = new THREE.SphereGeometry(1, 2, 2)
  const dotMaterial = new THREE.MeshStandardMaterial({
    wireframe: true,
    visible: false
  })

  const dotWrapperGeometry = new THREE.SphereGeometry(1, 2, 2)
  const dotWrapperMaterial = new THREE.MeshStandardMaterial({
    wireframe: true,
    visible: false
  })

  g.dot = new Object()

  g.dot.mesh = new THREE.Mesh(dotGeometry.clone(), dotMaterial.clone())
  g.dot.mesh.position.copy(panoramsData[0].task.dot)

  g.dot.meshWrapper = new THREE.Mesh(dotWrapperGeometry.clone(), dotWrapperMaterial.clone())
  g.dot.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotRotate)

  rotationWrapper.add(g.dot.meshWrapper.add(g.dot.mesh))

  g.dot.element = document.createElement('div')
  // g.dot.element.href = '#'
  g.dot.element.className = 'label'
  // g.dot.element.innerHTML = '<div className = "ringbase ring1"></div><div className="ringbase ring2"></div><div className="pulse"></div>'

  g.dot.element.textContent = ''
  const moonLabel = new CSS2DObject(g.dot.element)

  g.dot.element.addEventListener('click', (evt) => {
    evt.preventDefault()
    quiz.isPopupShow = true
  })
  g.dot.mesh.add(moonLabel)

  const labelsRenderer = new CSS2DRenderer()

  labelsRenderer.setSize(container.offsetWidth, container.offsetHeight)

  window.addEventListener('resize', function () {
    labelsRenderer.setSize(container.offsetWidth || window.innerWidth, container.offsetHeight || window.innerHeight)
  })

  labelsRenderer.domElement.classList.add('labels-renderer')
  labelsRenderer.domElement.classList.add('labels-renderer--hide-next-button')
  labelsRenderer.domElement.classList.add('labels-renderer--hide-point')
  document.querySelector('.app').appendChild(labelsRenderer.domElement)

  g.v.controls.minAzimuthAngle = panoramsData[0].minAzimuthAngle
  g.v.controls.maxAzimuthAngle = panoramsData[0].maxAzimuthAngle
  g.v.controls.minPolarAngle = panoramsData[0].minPolarAngle
  g.v.controls.maxPolarAngle = panoramsData[0].maxPolarAngle

  // кнопка следущей локации
  g.dotNextLocation = new Object()
  //
  g.dotNextLocation.mesh = new THREE.Mesh(dotGeometry.clone(), dotMaterial.clone())
  g.dotNextLocation.mesh.position.copy(panoramsData[0].task.dotNextLocation)

  g.dotNextLocation.meshWrapper = new THREE.Mesh(dotWrapperGeometry.clone(), dotWrapperMaterial.clone())
  g.dotNextLocation.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotNextLocationRotate)

  rotationWrapper.add(g.dotNextLocation.meshWrapper.add(g.dotNextLocation.mesh))
  //
  g.dotNextLocation.element = document.createElement('button')
  const buttonVisual = document.createElement('span')
  buttonVisual.classList.add('navigation-button-visual')
  buttonVisual.textContent = 'Следующая локация'
  g.dotNextLocation.element.appendChild(buttonVisual)
  g.dotNextLocation.element.disabled = true
  g.dotNextLocation.element.className = 'navigation-button'
  // g.dotNextLocation.element.textContent = 'Следующая локация'
  const dotNextLocation = new CSS2DObject(g.dotNextLocation.element)
  const finalButtons = new CSS2DObject(document.querySelector('.final-screen'))
  g.dotNextLocation.mesh.add(dotNextLocation)
  g.dotNextLocation.mesh.add(finalButtons)

  g.l.addLoop('trackObj', () => {
    labelsRenderer.render(g.v.scene, g.v.camera)
    // console.log(g.v.controls.getAzimuthalAngle(), g.v.controls.getPolarAngle())
  })

  initApp()
  initControlsButton(g)
}
