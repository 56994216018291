/* global Swiper */
import './modules/polyfill'
//import './rating'
import { director } from './modules/webgl/director'
import StateMachine from './modules/classes/state-machine'
import { panoramsData } from './panorams-data'
import { Buttons, Elements, Socials } from './const'
//import { auth } from './api'
import { quiz } from './quiz'
//import { Timer } from './modules/classes/timer'
//import { setResult } from './rating'
import { getFov, resetFov } from './modules/controls-button'
import { FOV } from './modules/webgl/gum/viewer'
//import { authCheck } from './auth-check'
//import { startTimer } from './timer-2'

const videoBg = document.querySelector('.bg-audio')
const videoVolumeBtn = document.querySelector('.start-screen__volume')

const toggleVideoVolume = (isOff = false) => {
  if (videoBg.muted && !isOff) {
    videoVolumeBtn.classList.remove('start-screen__volume--off')
    videoBg.play()
    videoBg.muted = false
  } else {
    videoVolumeBtn.classList.add('start-screen__volume--off')
    videoBg.pause()
    videoBg.muted = true
  }
}

videoVolumeBtn.addEventListener('click', (evt) => {
  evt.preventDefault()
  toggleVideoVolume()
})

export let currentLocation = 1
let appStateList = null

export const appStateMachine = new StateMachine()
/*const timer = new Timer('.header__timer-container', 0, () =>
  console.log('end timer')
)*/

const container = document.querySelector('.webgl')
const spinner = document.querySelector('.spinner')

setTimeout(() => {
  container.style.height = `${window.innerHeight}px`
  spinner.style.height = `${window.innerHeight}px`
  container.style.width = `${window.innerWidth}px`
  spinner.style.width = `${window.innerWidth}px`
}, 500)

window.addEventListener('resize', function () {
  container.style.height = `${window.innerHeight}px`
  spinner.style.height = `${window.innerHeight}px`
  container.style.width = `${window.innerWidth}px`
  spinner.style.width = `${window.innerWidth}px`
})

const swiper = new Swiper('.navigation__list', {
  observer: true,
  observeParents: true,
  slidesPerView: 6,
  spaceBetween: 22,
  freeMode: false,
  simulateTouch: false,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  centeredSlides: false,
  breakpoints: {
  // when window width is >= 320px
    320: {
      slidesPerView: 6,
      spaceBetween: 22
    },
    768: {
      slidesPerView: 11,
      spaceBetween: 30
    },
    1240: {
      slidesPerView: 11,
      spaceBetween: 30
    }
  }
})

window.addEventListener('DOMContentLoaded', () => {
  const initApp = () => {
    let isInitClickNextLocation = false

    const initClickNextLocation = () => {
      if (isInitClickNextLocation) {
        return
      }
      console.log('Init click next location')
      const nextLocationHandler = (evt) => {
        evt.preventDefault()
        isInitClickNextLocation = true
        const isQuiz = appStateMachine.current === appStateList.panoramaQuiz

        quiz.nextPanorama(() => {
          currentLocation++
          quiz.currentLocation = currentLocation
          changePanorama(currentLocation, director.g)
        }, isQuiz)
      }

      document.querySelector('.navigation-button').addEventListener('click', (evt) => {
        nextLocationHandler(evt)
      })

      document.querySelector('.final-screen__button--end').addEventListener('click', (evt) => {
        nextLocationHandler(evt)
      })

      /*document.querySelector('.final-screen__button--quest').addEventListener('click', () => {
        currentLocation = 1
        quiz.currentLocation = currentLocation
        changePanorama(currentLocation, director.g, true)
        appStateMachine.setState(appStateList.startScreen)
        appStateMachine.setState(appStateList.authorizationScreen)
      })
    */
      document.querySelector('.final-screen__button--restart').addEventListener('click', () => {
        currentLocation = 1
        quiz.currentLocation = currentLocation
        changePanorama(currentLocation, director.g, true)
        /*if (appStateMachine.current === appStateList.panoramaQuiz) {
          appStateMachine.setState(appStateList.startScreen)
          appStateMachine.setState(appStateList.authorizationScreen)
        } else {*/
          appStateMachine.setState(appStateList.startScreen)
          appStateMachine.setState(appStateList.excursionBegin)
        //}
      })

      // document.addEventListener('keydown', (evt) => {
      //   if (evt.code === 'Space') {
      //     nextLocationHandler(evt)
      //   }
      // })
    }
    appStateList = stateList(initClickNextLocation)
    appStateMachine.setState(appStateList.startScreen)

    Buttons.START.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.selectScreen)
    })

    Buttons.SELECT_CLOSE.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.startScreen)
    })

    /*Buttons.AUTH_CLOSE.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.selectScreen)
    })
    */
    Buttons.SELECT_EXCURSION.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.excursionBegin)
    })

    /*Buttons.SELECT_QUIZ.addEventListener('click', () => {
      appStateMachine.setState(appStateList.authorizationScreen)
    })


    Buttons.AUTH_VK.addEventListener('click', (evt) => {
      evt.preventDefault()

      if (!authCheck()) {
        return
      }

      auth(Socials.VK, () => {
        console.log('Авторизован в ВК')
        appStateMachine.setState(appStateList.quizBegin)
      }, () => {
        console.log('Ошибка авторизации')
      })
    })

    Buttons.AUTH_OK.addEventListener('click', (evt) => {
      evt.preventDefault()

      if (!authCheck()) {
        return
      }

      auth(Socials.OK, () => {
        console.log('Авторизован в OK')
        appStateMachine.setState(appStateList.quizBegin)
      }, () => {
        console.log('Ошибка авторизации')
      })
    })

    Buttons.AUTH_FB.addEventListener('click', (evt) => {
      evt.preventDefault()

      if (!authCheck()) {
        return
      }

      auth(Socials.FB, () => {
        console.log('Авторизован в FB')
        appStateMachine.setState(appStateList.quizBegin)
      }, () => {
        console.log('Ошибка авторизации')
      })
    })
    */
    Buttons.EXCURSION_POPUP_START.addEventListener('click', (evt) => {
      evt.preventDefault()
      Elements.EXCURSION_POPUP.classList.add('hide')
      setTimeout(() => {
        appStateMachine.setState(appStateList.panoramaExcursion)
        Elements.EXCURSION_POPUP.classList.remove('hide')
      }, 1500)
    })

    /*Buttons.QUIZ_POPUP_START.addEventListener('click', (evt) => {
      evt.preventDefault()
      Elements.QUIZ_POPUP.classList.add('hide')
      startTimer()
      setTimeout(() => {
        appStateMachine.setState(appStateList.panoramaQuiz)
        Elements.QUIZ_POPUP.classList.remove('hide')
      }, 3000)
    })*/

    Buttons.EXCURSION_POPUP_CLOSE.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.startScreen)
    })

    /*Buttons.QUIZ_POPUP_CLOSE.addEventListener('click', (evt) => {
      evt.preventDefault()
      appStateMachine.setState(appStateList.startScreen)
    })*/

    const spinner = document.querySelector('.spinner')
    spinner.classList.add('hide')

    const DEBUG = () => {
      appStateMachine.setState(appStateList.panoramaQuiz)

      currentLocation = 10
      quiz.currentLocation = currentLocation
      changePanorama(currentLocation, director.g)
    }

    // setTimeout(() => {
    //   DEBUG()
    // }, 2000)
  }
  director.init(initApp)
})

const changePanorama = (currentLocation, g, isRestart = false) => {
  if (appStateMachine.current === appStateList.panoramaQuiz) {
    console.log(document.querySelector('.navigation-button'))
    document.querySelector('.navigation-button').disabled = true
  }

  g.v.controls.reset()
  resetFov()
  g.v.camera.fov = (360 / Math.PI) * Math.atan(getFov(FOV) * (g.v.height / 1100))
  g.v.camera.updateProjectionMatrix()

  document.querySelector('.label').classList.remove('label--done')
  if (isRestart) {
    Elements.NAVIGATION_ITEMS.forEach((item) => {
      item.classList.remove('navigation__item--active')
    })
    Elements.NAVIGATION_ITEMS[0].classList.add('navigation__item--active')
    g.v.controls.minAzimuthAngle = panoramsData[0].minAzimuthAngle
    g.v.controls.maxAzimuthAngle = panoramsData[0].maxAzimuthAngle
    g.v.controls.minPolarAngle = panoramsData[0].minPolarAngle
    g.v.controls.maxPolarAngle = panoramsData[0].maxPolarAngle
    g.dot.mesh.position.copy(panoramsData[0].task.dot)
    g.dotNextLocation.mesh.position.copy(panoramsData[0].task.dotNextLocation)
    g.dot.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotRotate)
    g.dotNextLocation.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotNextLocationRotate)
    g.v.scene.children[1].rotation.setFromVector3(panoramsData[0].startMeshRotation)
    g.v.scene.children[1].children[0].material.map.dispose()
    g.v.scene.children[1].children[0].material.map = g.d_hideTextures.res.textures[1] ? g.d_hideTextures.res.textures[1] : g.d.res.textures[1]
    swiper.slideTo(0, 0)
    return
  }
  if (currentLocation === 21) {
    console.log('labels-renderer--hide-next-button')
    document.querySelector('.labels-renderer').classList.add('labels-renderer--hide-next-button')
    if (appStateMachine.current === appStateList.panoramaExcursion) {
      Elements.FINAL_SCREEN.classList.remove('hide')
    }

    if (appStateMachine.current === appStateList.panoramaQuiz) {
      document.querySelector('.quiz__button-next').textContent = 'Завершить'
    }
  }

  if (!Elements.NAVIGATION_ITEMS[currentLocation - 1]) {
    Elements.NAVIGATION_ITEMS[currentLocation - 2].classList.remove('navigation__item--active')
    Elements.NAVIGATION_ITEMS[0].classList.add('navigation__item--active')
    g.v.controls.minAzimuthAngle = panoramsData[0].minAzimuthAngle
    g.v.controls.maxAzimuthAngle = panoramsData[0].maxAzimuthAngle
    g.v.controls.minPolarAngle = panoramsData[0].minPolarAngle
    g.v.controls.maxPolarAngle = panoramsData[0].maxPolarAngle
    g.dot.mesh.position.copy(panoramsData[0].task.dot)
    g.dotNextLocation.mesh.position.copy(panoramsData[0].task.dotNextLocation)
    g.dot.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotRotate)
    g.dotNextLocation.meshWrapper.rotation.setFromVector3(panoramsData[0].task.dotNextLocationRotate)
    g.v.scene.children[1].rotation.setFromVector3(panoramsData[0].startMeshRotation)
    g.v.scene.children[1].children[0].material.map.dispose()
    g.v.scene.children[1].children[0].material.map = g.d_hideTextures.res.textures[1] ? g.d_hideTextures.res.textures[1] : g.d.res.textures[1]
    appStateMachine.setState(appStateList.startScreen)
    swiper.slideTo(0, 0)
    return
  }

  swiper.slideNext()
  g.v.controls.minAzimuthAngle = panoramsData[currentLocation - 1].minAzimuthAngle
  g.v.controls.maxAzimuthAngle = panoramsData[currentLocation - 1].maxAzimuthAngle
  g.v.controls.minPolarAngle = panoramsData[currentLocation - 1].minPolarAngle
  g.v.controls.maxPolarAngle = panoramsData[currentLocation - 1].maxPolarAngle
  g.dot.mesh.position.copy(panoramsData[currentLocation - 1].task.dot)
  g.dotNextLocation.mesh.position.copy(panoramsData[currentLocation - 1].task.dotNextLocation)

  g.v.scene.children[1].rotation.setFromVector3(panoramsData[currentLocation - 1].startMeshRotation)
  g.dot.meshWrapper.rotation.setFromVector3(panoramsData[currentLocation - 1].task.dotRotate)
  g.dotNextLocation.meshWrapper.rotation.setFromVector3(panoramsData[currentLocation - 1].task.dotNextLocationRotate)

  g.v.scene.children[1].children[0].material.map.dispose()
  g.v.scene.children[1].children[0].material.map = g.d_hideTextures.res.textures[currentLocation] ? g.d_hideTextures.res.textures[currentLocation] : g.d.res.textures[currentLocation]
  Elements.NAVIGATION_ITEMS[currentLocation - 2].classList.remove('navigation__item--active')
  Elements.NAVIGATION_ITEMS[currentLocation - 1].classList.add('navigation__item--active')
}

const stateList = (initClickNextLocation) => {
  return {
    startScreen: {
      name: 'startScreen',
      onStart () {
        Elements.FINAL_SCREEN.classList.add('hide')
        Elements.START.classList.remove('hide')
        Elements.NAVIGATION.classList.add('hide')
        Elements.START_CONTENT.classList.remove('hide')
      },
      condition () {
        return true
      },
      onEnd () {}
    },
    selectScreen: {
      name: 'selectScreen',
      onStart () {
        Elements.SELECT.classList.remove('hide')
      },
      condition () {
        return true
      },
      onEnd () {
        Elements.SELECT.classList.add('hide')
      }
    },
    authorizationScreen: {
      name: 'authorizationScreen',
      onStart () {
        Elements.AUTH.classList.remove('hide')
      },
      condition () {
        return true
      },
      onEnd () {
        Elements.AUTH.classList.add('hide')
      }
    },
    excursionBegin: {
      name: 'excursionBegin',
      onStart () {
        Elements.START_CONTENT.classList.add('hide')
        Elements.EXCURSION_BEGIN.classList.remove('hide')

        document.querySelector('.navigation-button').disabled = false
      },
      condition () {
        return true
      },
      onEnd () {
        Elements.EXCURSION_BEGIN.classList.add('hide')
      }
    },
    quizBegin: {
      name: 'quizBegin',
      onStart () {
        Elements.START_CONTENT.classList.add('hide')
        Elements.QUIZ_BEGIN.classList.remove('hide')
      },
      condition () {
        return true
      },
      onEnd () {
        Elements.QUIZ_BEGIN.classList.add('hide')
      }
    },
    panoramaExcursion: {
      name: 'panoramaExcursion',
      onStart () {
        quiz.isQuiz = false
        initClickNextLocation()
        toggleVideoVolume(true)
        Elements.NAVIGATION.classList.remove('hide')
        document.querySelector('.labels-renderer').classList.remove('hide')
        document.querySelector('.labels-renderer').classList.remove('labels-renderer--hide-next-button')
        document.querySelector('.labels-renderer').classList.remove('labels-renderer--hide-point')
        Elements.START.classList.add('hide')
      },
      condition () {
        return true
      },
      onEnd () {
        currentLocation = 1
        document.querySelector('.labels-renderer').classList.add('hide')
        document.querySelector('.labels-renderer').classList.add('labels-renderer--hide-next-button')
        document.querySelector('.quiz__button-next').textContent = 'Продолжить'
      }
    },
    /*panoramaQuiz: {
      name: 'panoramaQuiz',
      onStart () {
        quiz.isQuiz = true
        toggleVideoVolume(true)
        console.log(timer)
        timer.start()
        initClickNextLocation()
        Elements.NAVIGATION.classList.remove('hide')
        document.querySelector('.labels-renderer').classList.remove('hide')
        document.querySelector('.labels-renderer').classList.remove('labels-renderer--hide-next-button')
        document.querySelector('.labels-renderer').classList.remove('labels-renderer--hide-point')
        Elements.TIMER.classList.remove('hide')
        Buttons.NEXT_LOCATION.classList.remove('hide')
        Elements.TIMER_ICON.classList.remove('hide')
        Elements.START.classList.add('hide')
        document.querySelector('.navigation-button').disabled = true
      },
      condition () {
        return true
      },
      onEnd () {
        const time = timer.stop()
        setResult(time)
        document.querySelector('.labels-renderer').classList.add('hide')
        document.querySelector('.labels-renderer').classList.add('labels-renderer--hide-next-button')
        document.querySelector('.labels-renderer').classList.add('labels-renderer--hide-point')
        Elements.TIMER.classList.add('hide')
        Elements.TIMER_ICON.classList.add('hide')
        currentLocation = 1
        Buttons.NEXT_LOCATION.classList.add('hide')
        document.querySelector('.quiz__button-next').textContent = 'Продолжить'
      }
    }*/
  }
}
